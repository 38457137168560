import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Error } from '../dtos/error/error';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private errorCache = new Map<string, number>();
  private apiUrl = environment.api;
  private readonly THROTTLE_TIME = 1000;
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  constructor(private http: HttpClient) {}

  handleError(error: any): void {
    const errorKey = this.getErrorKey(error);
    const now = Date.now();

    if (
      this.errorCache.has(errorKey) &&
      now - this.errorCache.get(errorKey)! < this.THROTTLE_TIME
    ) {
      return;
    }

    this.errorCache.set(errorKey, now);
    const err: Error = {
      message: error.message ?? error | error.error,
      stack: error.stack ?? JSON.stringify(error.error),
      timestamp: error.error?.timestamp ?? new Date().toISOString(),
      url: error.url || ' ',
    };

    this.sendDiagnostic(err).subscribe({
      next: () => {},
      complete: () => {},
    });
  }

  private getErrorKey(error: any): string {
    return `${error.status}-${error.message}-${error.url}`;
  }

  sendDiagnostic(error: Error): Observable<Error> {
    return this.http.post<Error>(
      `${this.apiUrl}error`,
      error,
      this.httpOptions
    );
  }
}
